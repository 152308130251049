import React, { useEffect, useState } from 'react';
import * as Styled from './StyledComponents';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';
import { useNavigate, Link} from 'react-router-dom';

function Wishlist() {
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(JSON.parse(localStorage.getItem('wishlist')|| [])); //from Dashboard
  const [newItem, setNewItem] = useState('');
  const [newItemPrice, setNewItemPrice] = useState('');
  const [doUpdate, setDoUpdate] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [recoList, setrecoList] = useState(JSON.parse(localStorage.getItem('recolist') ||[])); //from Dashboard

  //  { id: 1, title: "Card 1", content: "This is the content of card 1" },
  //  { id: 2, title: "Card 2", content: "This is the content of card 2" },
  //  { id: 3, title: "Card 3", content: "This is the content of card 3" },
  //  { id: 4, title: "Card 4", content: "This is the content of card 4" },
 
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const openModal = (link) => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % recoList.length);
  };

  const prevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + recoList.length) % recoList.length);
  };

  const Modal = ({ isOpen, onClose, link }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl">
          <h2 className="text-xl font-bold mb-4">Link Modal</h2>
          <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
            Click here to open link
          </a>
          <button
            onClick={onClose}
            className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const user = auth.currentUser; 
  console.log("Wishlist set to :", wishlist);

  useEffect(() => {
    const updateWishList = async() => {
      try{
        console.log("Update database for wishlist from user:", user.uid);
        setWishlist(JSON.parse(localStorage.getItem('wishlist'))|| []);
        const wishDocRef =  doc(db,'wishlist', user.uid);
        await setDoc(wishDocRef, {userlist: wishlist}, {merge:true});
        console.log('Updated wishlist for user:', user.uid);
        setTimeout(() => {navigate('/dashboard')}, 1000);
      } catch (error) {
        console.error('Error updating wishlist data',error);
      }
    };
    if(doUpdate) {
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      updateWishList(); //write to firestore for this user
    } else {
      console.log("No do update");
    }
  }, [doUpdate, user]);

  const delItem = (index) => {
    const updatedList = wishlist.filter((_, i) => i!==index);
    setWishlist(updatedList);
  };

  if (user) {
    const addItem = () => {
      if (newItem.trim()) {
        setWishlist([...wishlist, {name: newItem.trim(), price: newItemPrice.trim()}]);
        setNewItem('');
        setNewItemPrice('');
        //setDoUpdate(true); 
        //console.log("Setting do update to true");
      }
    };
    return (
        <Styled.WishlistContainer>
          <Styled.Title>Wishlist</Styled.Title>
          <Styled.List>
          {wishlist.map((item, index) => (
                <Styled.ListItem key={index}>
                  <span>{item.name} </span>
                  <span> ${item.price}</span>
                  <Styled.CrossButton onClick={()=>delItem(index)}>X</Styled.CrossButton>
                </Styled.ListItem>
              ))}
          </Styled.List>
          <Styled.Input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Type item here..."
          />
          <Styled.Input
            type="number"
            value={newItemPrice}
            onChange={(e) => setNewItemPrice(e.target.value)}
            placeholder="Type item price here..."
          />
          <Styled.AddButton onClick={addItem}>+ Add to wishlist</Styled.AddButton>
          <Styled.AddButton onClick={() => setDoUpdate(true)}>Update Wish List</Styled.AddButton>
          <Link to="/dashboard">Return to Dashboard</Link>
       
       
          <Styled.Carousel>
            <div className="relative w-64 h-96">
              {recoList.map((card, index) => (
                <Styled.ItemCard onClick={openModal(card.link)}>
                  key={card.id}
                  className={`absolute top-0 left-0 w-full h-full transition-all duration-300 ease-in-out ${
                    index === currentIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'
                  }`}
                
                  <div className="bg-white rounded-lg shadow-lg p-6 h-full flex flex-col justify-between">
                    <h2 className="text-xl font-bold mb-4">{card.title}</h2>
                    <img src={card.pic} alt="Item" />
                    <p>{card.content}</p>
                  </div>
                </Styled.ItemCard>
              ))}
            </div>
            <div className="flex justify-between w-64 mt-4">
              <button
                onClick={prevCard}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
              <Styled.ChevronLeft />
              </button>
              <button
                onClick={nextCard}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                <Styled.ChevronRight />
              </button>
            </div>
          </Styled.Carousel>
        </Styled.WishlistContainer>

      );
  } else {
    localStorage.clear();
    return (
      navigate('/')
    );
  }
      
}

export default Wishlist;
