//Styled components
import styled from 'styled-components';

export const AppContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;

export const LoginForm = styled.form`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;


export const Title = styled.h2`
  margin-bottom: 2rem;
`;

export const Dropdown = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

/*
export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
*/

export const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  width: calc(100% - 2rem);
  max-width: 300px;
`;

export const SavingsCalculatorContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;


export const LogTasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;
export const WishlistContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;


export const List = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  background-color: #fff;
  color: #000;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center vertically */
`;


export const Income = styled.div`
  font-size: 2rem;
`;

export const SavingsCalculator = styled.div`
width: 300px;
  margin: 20px auto;
  text-align: center;
`;

export const ProgressBarBackground = styled.div`
  height: 30px;
  width: 75%;
  background-color: #e0e0df;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressBarFill = styled.div`
  height: 100%;
  background-color: green;
  transition: width 0.5s ease-in-out;
  width: ${(props) => props.percentage}%;
`;

export const WaysToEarnMore = styled.div``;

/* General styles for the button */

export const Button = styled.button`
  width: 100%;
  gap: 10px;
  padding: 0.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #444;
  }
`;

export const CrossButton = styled.button`
  background-color: #ff4d4d; /* Red background color */
  color: white; /* White text color */
  padding: 0.5rem;
  border: none; /* No border */
  border-radius: 50%; /* Circular button */
  width: 15px; /* Width of the button */
  height: 15px; /* Height of the button */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 12x; /* Font size for the cross */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
`;


export const CalculateButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #32CD32;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

export const AddButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;


export const Grid2Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 2rem;
  width: 80%;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  width: 80%;
  height: 90vh;
`;

export const Card = styled.div`
  background-color: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
`;

export const Modalh2 = styled.h2`
  margin-top: 2rem;
`;

export const ModalButton = styled.button `
  margin-top: 10px;
`;


export const Instructions = styled.ul`
  list-style: none;
  padding: 0;
`;

export const InstructionItem = styled.li`
  margin-bottom: 1rem;
`;

export const ItemCard = styled.div`
  background-color: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 8px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 2rem;
  text-align: center;
  border: none;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

export const Carousel = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-between;
 background-color: gray;
`;

export const ChevronLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);

export const ChevronRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);
