import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import * as Styled from './StyledComponents';
import {auth} from './firebase';
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";

function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validations, setValidations] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const handlePasswordChange = (e) => {
    const newPassword =  e.target.value;
    setPassword(newPassword);
    setValidations({
      minLength: newPassword.length >= 8,
      hasUpperCase: /[A-Z]/.test(newPassword),
      hasLowerCase: /[a-z]/.test(newPassword),
      hasNumber: /\d/.test(newPassword),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
    });
  };

  useEffect(() => {
    // Check if the user is already authenticated
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        navigate('/dashboard');
      } else if (user && !user.emailVerified){
        return (
          <Styled.AppContainer>
              <h2>Email not Verified</h2>
              <Styled.Button onClick={handleResendEmail}>Resend Verification email</Styled.Button>
          </Styled.AppContainer>
        );
      } else{
        localStorage.clear();
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  const handleResendEmail = async (e) => {
    e.preventDefault();      //Send verification email
    try {
        await sendEmailVerification(auth.currentUser);
        alert("Sending Verification Email -- Please check your email and follow instructions to complete Signup")
        navigate('/');
    } catch(error){
        console.log("Error code is", error);
        return;
    }
  }

  const handleSignUp = async (e) => {
    e.preventDefault();
    //Create the user, and then send email verification.
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      //Send verification email
      try {
        await sendEmailVerification(auth.currentUser);
        setTimeout(() => navigate('/'), 3000);
        alert("Sending Verification Email -- Please check your email and follow instructions to complete Signup")
      } catch(error){
        console.log("Error code is", error);
        return;
      }
    } catch (error) {
        if(error.code == 'auth/email-already-in-use') {
          alert("We already have a user with this email. Please sign in");
          setTimeout(() => navigate('/'), 3000);
        }
        return;
    }
 }
  console.log('Styled Components:', Styled); // Debugging statement
  return (
    <Styled.AppContainer>
      <Styled.LoginForm>
        <h2>Enter Your email</h2>
        <Styled.Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <h2>Enter A Password</h2>
        <Styled.Input
          type="password"
          placeholder="Password"
          value={password}
          //onChange={(e) => setPassword(e.target.value)}
          onChange = {handlePasswordChange}
          required
        />
        <ul>
        <li style={{ color: validations.minLength ? 'green' : 'red' }}>
          Minimum 8 characters
        </li>
        <li style={{ color: validations.hasUpperCase ? 'green' : 'red' }}>
          Contains uppercase letter
        </li>
        <li style={{ color: validations.hasLowerCase ? 'green' : 'red' }}>
          Contains lowercase letter
        </li>
        <li style={{ color: validations.hasNumber ? 'green' : 'red' }}>
          Contains number
        </li>
        <li style={{ color: validations.hasSpecialChar ? 'green' : 'red' }}>
          Contains special character
        </li>
        </ul>
        <Styled.Button onClick={handleSignUp}>Sign Up</Styled.Button>
      </Styled.LoginForm>
    </Styled.AppContainer>
  );
}

export default Signup;
