import React, { useState, useEffect } from 'react';
import './Header.css'; // Import your CSS file for styling
import logo from './BudJitGoogleLogoMini.png';
import {auth} from '../../../firebase';
import {signOut, onAuthStateChanged } from 'firebase/auth';

const version = 1.0;
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async(user) => {
      if (user  && user.emailVerified) {
        // User is signed in
        setIsAuthenticated(true);
        const idTokenResult = await user.getIdTokenResult();
        setIsAdmin(!!idTokenResult.claims.admin);
      } else {
        // User is signed out
        setIsAuthenticated(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      setIsAuthenticated(false);
      localStorage.clear();
    }).catch((error) => {
      console.error("LogOut failed: ", error);
    });
  }

  return (
    <header className="header">
      <div className="logo-placeholder">   
      <a href="/"> {/* Link to your homepage */}
          <img src={logo} alt="Bud-jit Logo" />
        </a>  
      </div>
      <div>VERSION:{version}</div>
      <div className="hamburger-menu" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      {isMenuOpen && (
        <nav className="dropdown-menu">
          <ul>
            <li><a href="/">Dashboard</a></li>
            <li>  {isAuthenticated ? (
                  <a href="/" onClick={handleLogout}>Logout</a>) : (
                  <a href="/">Log In</a>)}
            </li>
            <li><a href="/blog">Blog</a></li>
            <li> {isAdmin ? (<a href="/admin">Admin Page</a>): null } </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
