import React , { useState , useEffect} from 'react';
import * as Styled from './StyledComponents';
import { useNavigate , Link} from 'react-router-dom';
import {auth} from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {getDoc, setDoc, doc} from 'firebase/firestore';
import {db} from './firebase';

function Dashboard() {
  const navigate = useNavigate();
  const [wishlist, setwishlist] = useState([]);
  const [goal, setGoal] = useState([]);
  //const [totalgoal,setTotalGoal] = useState(0);
  const [savings, setSaving] = useState(0);
  const [income, setIncome] = useState(0);
  const [currUser, setCurrUser] = useState(auth.currentUser);
  const [isAuthenticated, setIsAuthenticated] = useState(); 
  const [recoList, setrecoList] = useState([]);

  useEffect(()=> {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // User is signed in
        setIsAuthenticated(true);
        setCurrUser(user);
      } else if (user && !user.emailVerified){
        setIsAuthenticated(false);
        alert("Please check your email and follow the directions in the verification email");
        navigate('/verifyemail');
      } else{
        // User is signed out
        setIsAuthenticated(false);
        navigate('/');
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
    }, [navigate]);

  useEffect(()=> {
    const getWishList = async () => {
      try {
        const idTokenResult = await currUser.getIdTokenResult();
        console.log("User idTokenresult:", idTokenResult);
        console.log("Query database for wishlist from user:", currUser.uid);
       const wishDocRef =  doc(db,'wishlist', currUser.uid);
       const wishSnapshot = await getDoc(wishDocRef);
       if(wishSnapshot.exists()) {
        const wishDoc = wishSnapshot.data();
        console.log("Get doc with id:", wishSnapshot.id );
        setwishlist(wishDoc.userlist || []);
        setGoal(wishDoc.saving_goals || []);
        //setTotalGoal(goal.reduce((sum, goal) => sum + parseFloat(goal.price), 0));
        setSaving(wishDoc.savings || 0);
        setIncome(wishDoc.income || 0);
        setrecoList(wishDoc.recolist || []);
        
        localStorage.setItem('wishlist', JSON.stringify(wishDoc.userlist || []));
        localStorage.setItem('goal', JSON.stringify(wishDoc.saving_goals || []));
        localStorage.setItem('savings', JSON.stringify(wishDoc.savings || 0));
        localStorage.setItem('income', JSON.stringify(wishDoc.income|| 0));
        localStorage.setItem('tasks', JSON.stringify(wishDoc.tasks || []));
        localStorage.setItem('recolist', JSON.stringify(recoList || []));
       } else {
        console.log("No such document!!-- CREATE ONE");
        /* income: derived sum from tasks
           saving: self reported savings
           saving_goals: [name, amount, needby]
           userlist: [name, price] 
           tasks: [name, amount]
           */
        const data = {
          createdAt: new Date(),
          email: currUser.email,
          income: 0, 
          savings: 0,
          userlist:[],
          tasks : [],
          saving_goals: []
        };
        // Add a  new document in collection "wishlist" with "auth.currUser.uid" as id
        console.log("Create wishlist document with id", currUser.uid);
        try {
          await setDoc(doc(db, 'wishlist', currUser.uid), data);
        } catch(error) {
          console.error("Error creating user record!!", error)
        }
        setwishlist([]);
        setGoal([]);
        setSaving(0);
        setIncome(0);
        setrecoList([]);
        localStorage.setItem('wishlist', JSON.stringify([]));
        localStorage.setItem('goal', JSON.stringify(0));
        localStorage.setItem('savings', JSON.stringify(0));
        localStorage.setItem('income', JSON.stringify(0));
        localStorage.setItem('tasks', JSON.stringify([]));
        localStorage.setItem('recolist', JSON.stringify([]));
       }            
      } catch (error) {
        console.error('Error fetching wishlist data',error);
      }
    };
    getWishList();
  }, [navigate, currUser]);

  if (!currUser) {
      console.log("User not found");
      return (
        <Styled.GalleryContainer>
        <h3>You are logged out. Please Login again</h3>
        <Link to="/">Login</Link>
        </Styled.GalleryContainer>
      );
    } else {
      // Calculate percentage and handle case where goal is 0 to avoid division by zero
      const tgoal = goal.reduce((sum, goal) => sum + parseFloat(goal.price), 0);
      console.log("totalgoal:", tgoal);
      console.log("savings:", savings);
      console.log("goal:", goal);
      const percentage = tgoal === 0 ? 0 : Math.min((savings / tgoal) * 100, 100);
    
      return (
      <Styled.GalleryContainer>
        <Styled.Title>Welcome to BUD-jit! Here is your portfolio!</Styled.Title>
        <Styled.Grid2Container>
          <Styled.Card onClick={() => navigate('/log-tasks')}>
            <h3>Monthly Income</h3>
            <Styled.Income>${income}</Styled.Income>
          </Styled.Card>
          <Styled.Card onClick={() => navigate('/savings-calculator')}>
            <h3>Savings Calculator</h3>
            <Styled.SavingsCalculator>
              <p>Your savings plan</p>
              <Styled.ProgressBarBackground>
                <Styled.ProgressBarFill percentage={percentage} />
              </Styled.ProgressBarBackground>
                <p>{parseFloat(percentage).toFixed(2)}% complete</p>
            </Styled.SavingsCalculator>
          </Styled.Card>
          <Styled.Card onClick={() => navigate('/wishlist')}>
            <h3>Wishlist</h3>
            <p>Add your Wishlist here</p>
            <Styled.List>
              {wishlist.map((item, index) => (
                <Styled.ListItem key={index}>
                  <span style={{fontWeight: 'bold'}}>{item.name}</span>
                  <span style={{fontWeight: 'bold', marginLeft: '10px' }}>${item.price}</span>
                </Styled.ListItem>
              ))}
            </Styled.List>
          </Styled.Card>
          <Styled.Card>
            <h3>Ways to earn more</h3>
            <Styled.WaysToEarnMore>
              <ul>
                <li>Walk dogs</li>
                <li>Babysit</li>
                <li>Take trash cans in/out</li>
                <li>Coach a sport (1 on 1 lesson)</li>
              </ul>
              <Link to="/blog">Read More!</Link>
            </Styled.WaysToEarnMore>
          </Styled.Card>
        </Styled.Grid2Container>
      </Styled.GalleryContainer>
      ); //return
    }
  
}

export default Dashboard;
