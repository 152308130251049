import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import * as Styled from './StyledComponents';
import {auth} from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

function EmailVerification() {
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  
  useEffect(()=> {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // User is signed in
        setTimeout(() => navigate('/dashboard'), 3000);
      } //else{
        // User is signed out
        //navigate('/');
      //}
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
    }, [navigate]);

  const handleResendVerification = async () => {
    try {
      await auth.currentUser.sendEmailVerification();
      alert('Verification email re-sent. Please check your inbox.');
    } catch (error) {
      console.error('Error resending verification email:', error.message);
    }
  };

  const handleTestVerification = async () => {
    const user =auth.currentUser;
    await user.reload();
    setTimeout(() => navigate('/'), 100);
  };

  return (
    <Styled.AppContainer>
      <h2>Email Verification</h2>
      {isVerified ? (
         <h2>Email is verified. Proceed to see your Dashboard</h2>
      ) : (
        <Styled.LoginForm>
          <Styled.Button onClick={handleTestVerification}> Email verified</Styled.Button>
          <h2>Didn't get Email?</h2>
          <Styled.Button onClick={handleResendVerification}>Resend Verification Email</Styled.Button>
        </Styled.LoginForm>
      )}
    </Styled.AppContainer>
  );
}

export default EmailVerification;
