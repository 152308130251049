import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './StyledComponents';
import {auth} from './firebase';
import { updatePassword} from "firebase/auth";

function UpdatePwd() {
  const [password, setPassword] = useState(''); 
  const navigate = useNavigate();
  const user = auth.currentUser;

  const getNewPwd = async (e) => {
   e.preventDefault();
   //If user exists, then go to handleLogin
    try {
      await updatePassword(user, password);
      alert('Updating Password');
      navigate('/');
    } catch (error) {
      alert("Sorry we were unable to update your password")
    }
 }

return (
  <Styled.AppContainer>
    <Styled.LoginForm onSubmit={getNewPwd}>
      <h2>Please Enter new password</h2>
      <Styled.Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <Styled.Button type="submit">UpdatePasword</Styled.Button>
    </Styled.LoginForm>
  </Styled.AppContainer>
);
}
export default UpdatePwd;
