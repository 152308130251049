import React, { useState , useEffect} from 'react';
import styled from 'styled-components';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';
import * as Styled from './StyledComponents';
import {Link, useNavigate} from 'react-router-dom';

const Total = styled.h3`
  margin-top: 2rem;
`;

function LogTasks() {
  const [tasks, setTasklist] = useState(JSON.parse(localStorage.getItem('tasks'))|| []); //from Dashboard
  const [total, setTotal] = useState(tasks.reduce((sum, task) => sum + parseInt(task.amount, 10), 0));
  const [newTask, setNewTask] = useState('');
  const [newTaskAmount, setNewTaskAmount] = useState(0);
  const [doUpdate, setDoUpdate] = useState(false);
  const user = auth.currentUser; 
  const navigate= useNavigate();

  useEffect(() => {
    const updateTaskList = async() => {
      try{
        console.log("Update database for wishlist from user:", user.uid);
        const wishDocRef =  doc(db,'wishlist', user.uid);
        await setDoc(wishDocRef, {tasks: tasks}, {merge:true});
        await setDoc(wishDocRef,{income:total}, {merge:true});
        localStorage.setItem('income', JSON.stringify(total));
        localStorage.setItem('tasks', JSON.stringify(tasks));
        setTimeout(() => navigate('/dashboard'), 1000);

      } catch (error) {
        console.error('Error updating tasks data',error);
      }
    };
    if(doUpdate){
      updateTaskList();
    } //write to firestore for this user
  }, [doUpdate, user]);

  const addTask = () => {
    if (newTask.trim()) {
      setTasklist([...tasks, {name: newTask.trim(), amount: newTaskAmount.trim()}]);
      setNewTask('');
      setNewTaskAmount('');
    }
  };

  const delTask = (index) => {
    const updatedTask = tasks.filter((_, i) => i!==index);
    setTasklist(updatedTask);
  };

  const handleDoneTaskUpdate= () => {
    //Update the localStorage
    localStorage.setItem('tasks', JSON.stringify(tasks));
    //Update the total
    setTotal(tasks.reduce((sum, task) => sum + parseInt(task.amount, 10), 0));
    localStorage.setItem('income', JSON.stringify(total));
    setDoUpdate(true); 
  };

  return (
    <Styled.LogTasksContainer>
      <Styled.Title> Log your monthly income here!</Styled.Title>
        <p>Let me guess... You found some money on trees? No worries! Log extra
        money making here!
      </p>
      <Styled.List>
        {tasks.map((task, index) => (
          <Styled.ListItem key={index}>
            <span> {task.name}</span>
            <span>  ${task.amount}</span>
            <Styled.CrossButton onClick={()=>delTask(index)}>X</Styled.CrossButton>
          </Styled.ListItem>
        ))}
      </Styled.List>
      <Total>Total: ${total}</Total>
      <Styled.Input
            type="text"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            placeholder="Name for New Income Source"
          />
          <Styled.Input
            type="number"
            value={newTaskAmount}
            onChange={(e) => setNewTaskAmount(e.target.value)}
            placeholder="Amount earned from New Income Source"
          />
          <Styled.AddButton onClick={addTask}>+ Add income to your income list</Styled.AddButton>
          <Styled.AddButton onClick={handleDoneTaskUpdate}>Done Updating Income Sources</Styled.AddButton>
          <Link to="/dashboard">Return to Dashboard</Link>
    </Styled.LogTasksContainer>
  );
}

export default LogTasks;
