import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from './Login';
import Dashboard from './Dashboard';
import LogTasks from './LogTasks';
import Wishlist from './Wishlist';
import SavingsCalculator from './SavingsCalculator';
import Blog from './Blog';
import PwdReset from './PwdReset';
import EmailVerification from './EmailVerification';
import Signup from './Signup';
import UpdatePwd from './UpdatePwd';
import AdminPage from './AdminPage';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import './App.css';

function App() {

// NotFound Component
const NotFound = () => {
  // Alert the user about the unmatched route
  alert('This route does not exist!');
  return (
    <div>
      <h1>404 - Not Found!</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/verifyemail" element={<EmailVerification/>}/>       
        <Route path="/updatepwd" element={<UpdatePwd/>}/> 
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/log-tasks" element={<LogTasks />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/savings-calculator" element={<SavingsCalculator />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/pwdReset" element={<PwdReset />} />
        <Route element={<ProtectedAdminRoute />}>
          <Route path="/admin" element={<AdminPage />} />
        </Route>
        {/* Catch-all route for unmatched paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
