import React, { useState } from 'react';
import * as Styled from './StyledComponents';
import { db, storage, auth } from './firebase'; // Assuming you have a firebase.js file with your Firebase configuration
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Link} from 'react-router-dom';

const AdminPage = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [editedItem, setEditedItem] = useState({});
  const [newItem, setNewItem] = useState({
    Name: '',
    Category: '',
    Price: '',
    Description: '',
    Picture: null
  });

  const currUser = useState(auth.currentUser);

  const fetchItems = async () => {
    try {
    const querySnapshot = await getDocs(collection(db, 'FullItemList'));
    const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setItems(itemsData);
    } catch(error) {
      console.log("No such document!!");
      setItems([]);
    }
  };

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
    setModalMode('options');
  };

  const handleEdit = () => {
    setEditedItem(selectedItem);
    setModalMode('edit');
  };

  const handleDelete = () => {
    setModalMode('delete');
  };
  console.log('Styled Components:', Styled); // Debugging statement
 if(!currUser){
    console.log("User not found");
    return (
      <Styled.GalleryContainer>
      <h3>You are an Op- Go away</h3>
      <Link to="/">Login</Link>
      </Styled.GalleryContainer>
    );
  } else {
  const handleEditSubmit = async () => {
    const itemRef = doc(db, 'FullItemList', editedItem.id);
    await updateDoc(itemRef, editedItem);
    setIsModalOpen(false);
    fetchItems();
  };

  const handleDeleteConfirm = async () => {
    const itemRef = doc(db, 'FullItemList', selectedItem.id);
    await deleteDoc(itemRef);
    setIsModalOpen(false);
    fetchItems();
  };

  const handleAddNew = () => {
    setModalMode('add');
    setIsModalOpen(true);
  };

  const uploadImage = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `FullItemList/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleAddSubmit = async () => {
    try {
      const imageUrl = await uploadImage(newItem.Picture);
      await addDoc(collection(db, 'FullItemList'), { ...newItem, Picture: imageUrl });
    } catch (error) {
      console.error("Error saving item:", error);
    }
    setIsModalOpen(false);
    fetchItems();
  };

  const renderModal = () => {
    switch (modalMode) {
      case 'options':
        return (
          <div className="modal">
            <button onClick={handleEdit}>Edit</button>
            <button onClick={handleDelete}>Delete</button>
            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
          </div>
        );
      case 'edit':
        return (
          <div className="modal">
            <input
              value={editedItem.Name}
              onChange={(e) => setEditedItem({...editedItem, Name: e.target.value})}
              placeholder="Name"
            />
            <input
              value={editedItem.Category}
              onChange={(e) => setEditedItem({...editedItem, Category: e.target.value})}
              placeholder="Category"
            />
            <input
              value={editedItem.Price}
              onChange={(e) => setEditedItem({...editedItem, Price: e.target.value})}
              placeholder="Price"
            />
            <textarea
              value={editedItem.Description}
              onChange={(e) => setEditedItem({...editedItem, Description: e.target.value})}
              placeholder="Description"
            />
            <input
              type="file"
              onChange={(e) => setEditedItem({...editedItem, Picture: e.target.files[0]})}
            />
            <button onClick={handleEditSubmit}>Submit</button>
            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
          </div>
        );
      case 'delete':
        return (
          <div className="modal">
            <p>Are you sure you want to delete this item?</p>
            <button onClick={handleDeleteConfirm}>Yes</button>
            <button onClick={() => setIsModalOpen(false)}>No</button>
          </div>
        );
      case 'add':
        return (
          <div className="modal">
            <input
              value={newItem.Name}
              onChange={(e) => setNewItem({...newItem, Name: e.target.value})}
              placeholder="Name"
            />
            <input
              value={newItem.Category}
              onChange={(e) => setNewItem({...newItem, Category: e.target.value})}
              placeholder="Category"
            />
            <input
              value={newItem.Price}
              onChange={(e) => setNewItem({...newItem, Price: e.target.value})}
              placeholder="Price"
            />
            <textarea
              value={newItem.Description}
              onChange={(e) => setNewItem({...newItem, Description: e.target.value})}
              placeholder="Description"
            />
            <input
              type="file"
              onChange={(e) => setNewItem({...newItem, Picture: e.target.files[0]})}
            />
            <button onClick={handleAddSubmit}>Add Item</button>
            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Styled.GalleryContainer>
        <Styled.Title>Welcome to BUD-jit Admin Page! Manage the products</Styled.Title>
        <Styled.Grid2Container>
          <Styled.Card onClick={handleAddNew}>
            <h3>Add New Item</h3>
            {items.map(item => (
              <div key={item.id} className="card" onClick={() => handleCardClick(item)}>
              <img src={item.Picture} alt={item.Name} />
              <h3>{item.Name}</h3>
              <p>{item.Category}</p>
              <p>${item.Price}</p>
              </div>
      ))}
      </Styled.Card>
      {isModalOpen && renderModal()}
      </Styled.Grid2Container>
    </Styled.GalleryContainer>
  );
};

}
export default AdminPage;