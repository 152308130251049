import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import * as Styled from './StyledComponents';
import {auth} from './firebase';
import { signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 
  useEffect(() => {
    // Check if the user is already authenticated
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        navigate('/dashboard');
      } else if(user && !user.emailVerified){
        navigate('/verifyemail')
      }else{
        localStorage.clear();
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  const handleLogin = async (e) => {
    e.preventDefault();
    //If user does not exists, then go to handleLogin
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (err) {
      console.log("Login failed: ", err);
      if(err.code === 'auth/user-not-found') {
        alert("User not found. Please click the New User Button");
        return;
      }
      else if (err.code === 'auth/invalid-email'){
        alert("Please enter a vaild email address");
        return;
      }
      else if(err.code === 'auth/wrong-password'){
        alert("Incorrect password. Please try again");
        return;
      }
      else {
        alert("Login failed. Please try again");
        return;
      }
    }
  };
  console.log('Styled Components:', Styled); // Debugging statement
  return (
    <Styled.AppContainer>
      <Styled.LoginForm>
        <h2>Welcome to BUD-jit</h2>
        <Styled.Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Styled.Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Styled.Button onClick={handleLogin}>Sign In</Styled.Button>
        <p><Link to="/signup">New User Sign Up</Link></p>
        <p><Link to="/pwdreset">  Forgot password?</Link></p>
      </Styled.LoginForm>
    </Styled.AppContainer>
  );
}

export default Login;
