//PwdReset.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './StyledComponents';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import {auth} from './firebase';

function PwdReset() {
  const [email, setEmail] = useState(''); 
  const navigate = useNavigate();

  const handleReset = async (e) => {
    e.preventDefault();
    //If user exists, then go to handleLogin
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Sent Password reset to your email')
      navigate('/');
    } catch (error) {
      alert('Cant send Password reset email' , error)
    }
  }
  console.log('Styled Components:', Styled); // Debugging statement
  return (
    <Styled.AppContainer>
      <Styled.LoginForm onSubmit={handleReset}>
        <h2>Please Enter your email</h2>
        <Styled.Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Styled.Button type="submit">Send Password Reset Email</Styled.Button>
      </Styled.LoginForm>
    </Styled.AppContainer>
  );
}

export default PwdReset;
